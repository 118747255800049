<template>
<div class="modal fade" id="contactdetail" tabindex="-1" role="dialog" aria-labelledby="contactdetail" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="contact_image pt-4 pb-2">
                <i class="fas fa-user fa-5x" style="color: #230B34;" ></i>
            </div>
            <div class="modal-header pt-4 pb-2">
                <h3 class="contact_detail_header">
                        Malik
                </h3>
            </div>
            <div class="modal-body m-0 p-0">
                <div class="contact_main_content">
                
                    <p class="contact_text_detail_header ">
                        Phone
                    </p>
                    <p class="contact_detail_text">
                        0249809695
                    </p>
                
                    <p class="contact_text_detail_header ">
                        Date of birth
                    </p>
                    <p class="contact_detail_text">
                        22/04/1998
                    </p>

                    <p class="contact_text_detail_header">
                        Groups
                    </p>
                    <p class="contact_detail_text detail_122             ">
                        22/04/1998
                    </p>

                </div>
                <div class="modal__footer__btns">
                    <button  class="cancel" @click="deleteAction">Delete</button>
                    <button  class="cancel" @click="editAction">Edit</button>
                    <el-button type="primary" id="cancelinput" @click="hideModal">Cancel</el-button>
                </div>
            </div>
        </div>
    </div>  
</div>
</template>
<script>
import store from '@/state/store.js'
let $ = require('jquery')
export default {
    name:'contact-detail',
    data() {
        return {
            inputFormData: {
                name:'',
                description:''
            },
            rules: {
                name: [
                    {required: true, message:'Group name is required', trigger:'change'}
                ]
            },
            continueModalContent: {
                title:"",
                text:'',
                type:''
            }
        }
    },
    computed: {
        contactDetail() {
            return store.getters['uistate/contactDetail']
        }
    },
    methods: {
        deleteAction() {
            $('#contactdetail').modal('hide')
            setTimeout(function() {
                $('#continuedit').modal('show')
            },1000)
            this.continueModalContent = {
                title:'Editing contact would permanently edit from all associated  groups',
                text : "Contact is in Special listing, Group 2, Group 3, Group 8",
                type:'delete'
            }
        },
        editAction(){
            $('#contactdetail').modal('hide')
            setTimeout(function() {
                $('#continuedit').modal('show')
            },1000)
            this.continueModalContent = {
                title:'Editing contact would permanently edit from all associated  groups',
                text : "Contact is in Special listing, Group 2, Group 3, Group 8",
                type:'edit'
            }
        },
        continueEditAction(){

        },
        hideContinueEditModal(){
           $('#continuedit').modal('hide')  
        },
        continueDeleteAction(){
            
        },
        hideModal(){
            $('#contactdetail').modal('hide')
            this.inputFormData = {
                name:'',
                description:''   
            }
        },

    }
}
</script>

<style scoped>
.modal__footer__btns{
    display: flex;
    margin: 2.5em 1em;
    justify-content: space-between;
}
.cancel{
    background: #F5F5F5;
    border-radius: 5px;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9E9EA7;
    outline: none;
    border: none;
    padding:.7em 4em;
}
#cancelinput,#deleteinput{
    padding:.7em 3em!important;
}
.form_container{
    display: flex;
    justify-content: center;
}
.contact_image {
    height: 243px;
    background: #F6F6F6;
    border-radius: 10px 10px 0px 0px;
    padding:0px!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_detail_header{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #575761;
}
.contact_main_content{
    padding:1em;
}
.contact_text_detail_header{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484A4F;
    margin-bottom: .7em !important;
}
.contact_detail_text{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #484A4F;
}
.continue_edit_header{
    display: flex;
    justify-content: center;
    align-items: center;
}
._edit_icon{
    margin-top:4em;
    margin-bottom: 4em;
}
.continue_edit_header_title{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #575761;
}
.continue_edit_header_text{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #575761;
    padding-top: .3em;
}
.modal-content{
    padding-left: 5em!important;
    padding-right: 6em!important;
}
</style>